.customize-theme-section {
  &.offcanvas {
    &.offcanvas-end {
      max-width: 30%;
      min-width: 400px;
    }
  }

  .theme-selector{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    .color-item{
      padding: 5px;
      margin-bottom: 10px;
      border:2px solid darkgray;
      border-radius: 50%;
      position: relative;

      &.selected {
        border-color: #3bb800 !important;
          &:before {
            align-items: center;
            background: #3bb800;
            border-radius: 50px;
            color: #f4f4f9;
            content: "✓";
            display: flex;
            font-family: Line Awesome Free;
            font-size: 10px;
            font-weight: 900;
            height: 20px;
            justify-content: center;
            position: absolute;
            left: calc(50% - 10px);
            top: calc(50% - 10px);
            transform: translate(0);
            width: 20px;
          }
        }

      div{
        width: 50px;
        height: 50px;
        border-radius: 50%;

        // &.theme-1{        
        //   background-color: #2f7aaf;
        // }
        // &.theme-2{        
        //   background-color: #af2f2f;
        // }
        // &.theme-3{        
        //   background-color: #1B5E20;
        // }
      }
      
      cursor: pointer;
      
    }
  }
}

.change-password-section{
  &.offcanvas {
    &.offcanvas-end {
      max-width: 30%;
      min-width: 400px;
    }
  }
}