/* vars */
:root {
  --primary-base-theme-color: #2f7aaf;
  --primary-blue-gradeient-start: #2f7aaf;
  --primary-base-theme-gradeient-end: #dde7f3;
  --primary-base-theme-color-2: #6b5ec9;
}

* {
  font-family: 'Smitch Sans' !important;
}

.dh-table-basic {
  border-radius: 5px !important;
  .rdt_TableHead {
    .rdt_TableHeadRow{
      background-color: var(--primary-base-theme-color);
    }
    .rdt_TableCol {
      background-color: var(--primary-base-theme-color);
      color: #fffefe;
      font-weight: 500 !important;
    }
  }
}

.offcanvas-header {
  background: linear-gradient(90deg, var(--primary-base-theme-color), var(--primary-base-theme-gradeient-end));
  color: #fffefe;
  border-radius:10px;
}